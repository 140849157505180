import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { marked } from 'marked';
import DOMPurify from "dompurify";



// Regex to match valid (safe) URLs
// const protoRegex = /^(?:https?|ftps?):/gim;
const safeDomains = [
    window.origin,
    "https://www.dynatrace.com"
];

/**
 * Hook into the sanitized output to make sure output URLs
 * are absolute and open in a new tab
 */
DOMPurify.addHook('afterSanitizeAttributes', (node: Element) => {
    // set all elements owning target to target=_blank
    if ('target' in node) {
        node.setAttribute('target', '_blank');
    }

    // set non-HTML/MathML links to xlink:show=new
    if (
        !node.hasAttribute('target') &&
        (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
    ) {
        node.setAttribute('xlink:show', 'new');
    }

    const anchor = document.createElement('a');
    function cleanAttribute(attr) {
        if (node.hasAttribute(attr)) {
            anchor.href = node.getAttribute(attr);
            // if (anchor.protocol && protoRegex.test(anchor.protocol)) {
            //     node.removeAttribute(attr);
            // }
            // Check if the domain of the link is known to be safe
            if (anchor.protocol) {
                if (
                    // dynatrace.com TLD is assumed to be safe
                    !anchor.origin.endsWith(".dynatrace.com") &&
                    !anchor.origin.endsWith(".dynatracelabs.com") &&
                    !safeDomains.includes(anchor.origin)
                )
                    node.removeAttribute(attr);
            }
        }
    }

    cleanAttribute("href");
    cleanAttribute("action");
    cleanAttribute("xlink:href");

    // Cleanup
    anchor.remove();
});

/**
 * Renders Markdown into Sanitized HTML
 * @param text markdown format string
 * @param renderOpts markdown rendering options
 * @param sanitizeOpts sanitizer options
 * @returns
 */
export const markdownToHtml = (text: string, renderOpts: marked.MarkedOptions = {}, sanitizeOpts: DOMPurify.Config = {}) => {
    if (!text) return '';
    const rendered = marked(text, renderOpts);
    return DOMPurify.sanitize(rendered, sanitizeOpts) as string;
};


/**
 * Url Sanitizer pipe.
 *
 * This trusts URLs that exist in a safe list defined in our environments.ts file.
 * Any other URLs will NOT be trusted, thus will not be loaded.
 */
@Pipe({
    name: 'markdown',
    standalone: true
})
export class MarkdownPipe implements PipeTransform {

    constructor() { }

    public transform(markdownText: string): SafeUrl {
        return markdownToHtml(markdownText);
    }
}
